<template>
  <div class="home">
<!--    <elMenu></elMenu>-->
    <!--    content1-->
    <div>
      <img src="../../../public/images/hardware/head.jpg" width="100%" height="100%">
    </div>
    <!--    content3-->
    <div class="hardwareScheme_box">
      <div class="content3 content3_box_top">
        <div class="content3_box">
          <div class="content3_box_1" @click="pet">
            <img src="../../../public/images/hardware/1.png" width="100%">
            <div class="content3_box_sise">宠物喂食器</div>
          </div>
          <div class="content3_box_1" @click="lowPowerConsumption">
            <img src="../../../public/images/hardware/2.jpg" width="100%">
            <div class="content3_box_sise">低功耗摄像机</div>
          </div>
          <div class="content3_box_1" @click="network">
            <img src="../../../public/images/hardware/3.jpg" width="100%">
            <div class="content3_box_sise">网络摄像机和灯的集成</div>
          </div>
        </div>
      </div>
      <!--    content3-->
      <div class="content3">
        <div class="content3_box">
          <div class="content3_box_1" @click="bluetooth">
            <img src="../../../public/images/hardware/4.jpg" width="100%">
            <div class="content3_box_sise">蓝牙音箱的集成</div>
          </div>
          <div class="content3_box_1" @click="liveStreaming">
            <img src="../../../public/images/hardware/5.jpg" width="100%">
            <div class="content3_box_sise">直播的应用</div>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
    <navigation></navigation>
  </div>
</template>

<script>
import foot from '../../../src/components/foot'
import elMenu from "../../components/elMenu";
import navigation from "../../components/navigation";

export default {
  name: "hardwareScheme",
  components: {
    elMenu, foot,navigation
  },
  methods: {
    pet(){
      this.$router.push('/pet')
    },
    liveStreaming(){
      this.$router.push('/liveStreaming')
    },
    bluetooth(){
      this.$router.push('/bluetooth')
    },
    lowPowerConsumption(){
      this.$router.push('/lowPowerConsumption')
    }
    ,
    network(){
      this.$router.push('/network')
    }
  }
}
</script>

<style scoped>
.content3 {
  width: 1360px;
  height: 450px;
  margin: 0px auto;
  padding: 30px 0px 0px 0px;
}

.content3_box {
  padding-top: 20px;
  display: flex;
  justify-content: space-around;
}

.content3_box_1 {
  width: 20%;
  height: 400px;
  border: 1px solid #ececec;
}

.content3_box_sise {
  line-height: 100px;
  text-align: center;
  font-size: 18px;
}

.hardwareScheme_box{
  width:100%;
  height:1100px;
}
</style>